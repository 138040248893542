<template>
  <div class="WelcomeText" v-if="page < 2">
    <div class="page_wrapper">
      <div class="page" v-if="page === 0">
        <p class="bold center">Лöсьöд рöдвуж пас! Серпасав танi!</p>
        <p></p>
        <p class="bold center">
          Создай семейный знак! Нарисуй собственный знак прямо здесь!
        </p>
        <p class="bold">Чолöм!</p>
        <p class="bold">Привет!</p>
        <p>
          От времён глубокой древности до нас дошли разного рода схематические
          рисунки. Традиция ставить знаки существовала почти у всех древних
          народов, но мало кто сохранил их образцы до сегодняшнего дня. Коми это
          удалось.
        </p>
        <p>
          Пасы – это знаки собственности у коми, которыми отмечали охотничьи
          угодья, жилища, бытовые предметы. Они изначально имели свои названия:
        </p>
        <div class="pases_list">
          <div class="pas">
            <img src="/images/pases_pictures/1.svg" />
            <p class="name center">стрела<br />(ńov)</p>
          </div>
          <div class="pas">
            <img src="/images/pases_pictures/2.svg" />
            <p class="name center">лапа утки<br />(tšöž lap)</p>
          </div>
          <div class="pas">
            <img src="/images/pases_pictures/3.svg" />
            <p class="name center">
              женщина наматывает пряжу<br />(baba bydmaśe)
            </p>
          </div>
          <div class="pas">
            <img src="/images/pases_pictures/4.svg" />
            <p class="name center">разветвление, дорога<br />(vož)</p>
          </div>
          <div class="pas">
            <img src="/images/pases_pictures/5.svg" />
            <p class="name center">хвост белки<br />(ur böž)</p>
          </div>
        </div>
        <p>
          Родовой знак служил и своеобразным оберегом. Пас передавался от отца к
          младшему сыну – наследнику – в неизменённом виде, а к пасам старших
          сыновей добавлялись новые детали. В Коми есть семьи, которые до сих
          пор пользуются этими символами. Многие дедовские промысловые угодья и
          сейчас обозначаются охотниками родовым пасом.
        </p>
      </div>
      <div class="page" v-if="page === 1">
        <p class="bold center">Попробуй составить свой знак!</p>

        <p>Выбери снизу экрана нужный символ и перетяни его на поле.</p>
        <p>С помощью инструментов можно изменять исходные символы:</p>
        <div class="tools">
          <img src="/images/ui/move.svg" /><span>Перемещать</span>
          <img src="/images/ui/rotate.svg" /><span>Вращать</span>
          <img src="/images/ui/scale.svg" /><span>Масштабировать</span>
          <img src="/images/ui/delete.svg" /><span
            >Удалять отдельные символы</span
          >
          <img src="/images/ui/close.svg" /><span>Удалить все символы</span>
        </div>
        <p>Можешь выбрать несколько символов и расположить их по-своему.</p>
        <p>Когда твой пас будет готов, можешь с помощью кнопки:</p>
        <div class="tools">
          <img src="/images/ui/save.svg" /><span
            >Сохранить его на компьютер</span
          >
          <!--<img src="/images/ui/vk.svg" v-if="vkinit"><span v-if="vkinit">Опубликовать на стене ВКонтакте</span>-->
        </div>
        <p>А с помощью кнопки:</p>
        <div class="tools">
          <img src="/images/ui/info.svg" /><span>Посмотреть инструкцию</span>
        </div>
      </div>
    </div>
    <div class="button prev" @click="prevPage" v-if="page == 1">
      <span>Назад</span>
    </div>
    <div class="button next" @click="nextPage"><span>Далее</span></div>
  </div>
</template>

<script>
export default {
  name: "WelcomeText",
  props: {
    vkinit: Boolean,
    page: Number,
  },
  data() {
    return {};
  },
  methods: {
    nextPage() {
      this.$emit("nextPage");
    },
    prevPage() {
      this.$emit("prevPage");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.WelcomeText {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  /* background-color: rgba(255,255,255,0.5); */
  z-index: 10;
  color: black;
  /*text-shadow: 1px 1px 0px black;*/ /*, 1px 0px 0px black, 0px 1px 0px black, -1px -1px 0px black, -1px 0px 0px black, 0px -1px 0px black;*/
  font-family: "Times New Roman", Times, serif;
}

.WelcomeText.notDisplay {
  display: none;
}

.WelcomeText p {
  font-size: 22pt;
  margin-bottom: 3px;
  text-align: justify;
  color: black;
}

.page_wrapper {
  margin: 50px;
}

.WelcomeText p.bold {
  font-weight: bold;
}

.WelcomeText p.center {
  text-align: center;
}

.button {
  position: absolute;
  bottom: 20px;
  width: auto;
  height: auto;
  border: none;
  border-radius: 0px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 150px;
  height: 72px;
}
.button span {
  font-size: 20pt;
}

.button.next {
  background-image: url("/images/ui/next.svg");
  right: 40px;
  animation: right 1s infinite linear;
}

.button.next span{
  margin-left:-40px;
}

.button.prev {
  background-image: url("/images/ui/prev.svg");
  left: 40px;
  animation: left 1s infinite linear;
}

.button.prev span{
  margin-right:-40px;
}

.button:hover {
  box-shadow: none;
}

@keyframes left {
  0% {
    left: 40px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 40px;
  }
}

@keyframes right {
  0% {
    right: 40px;
  }
  50% {
    right: 20px;
  }
  100% {
    right: 40px;
  }
}

.pases_list {
  display: flex;
  justify-content: space-between;
}
.pases_list .pas img {
  width: 200px;
}

.pases_list p.name {
  font-size: 18pt;
}

.pases_list .pas {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 6fr;
  align-items: center;
  font-size: 18pt;
  font-weight: bold;
}
.tools img {
  width: 70px;
}
</style>

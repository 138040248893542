import Vue from 'vue'
import App from './App.vue'

export let WIDTH, HEIGHT, SCALE, MARGINLEFT;

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')


Array.prototype.shuffle = function() {
    for (var i = this.length - 1; i > 0; i--) {
        var num = Math.floor(Math.random() * (i + 1));
        var d = this[num];
        this[num] = this[i];
        this[i] = d;
    }
    return this;
}


function setScale() {
    WIDTH = document.documentElement.clientWidth;
    HEIGHT = document.documentElement.clientHeight;
    var p = WIDTH / HEIGHT;
    if (p > 1.25) {
        SCALE = HEIGHT / 1024.0;
        MARGINLEFT = Math.floor((WIDTH - (HEIGHT / 4 * 5)) / 2);
    } else {
        SCALE = WIDTH / 1280.0;
        MARGINLEFT = 0;
    }
    document.body.style.marginLeft = MARGINLEFT + 'px';
    document.body.style.transform = 'scale(' + SCALE + ')';
}


window.addEventListener(`resize`, setScale, false);
window.addEventListener(`DOMContentLoaded`, setScale, false);
window.addEventListener("load", () => {
    var loading = document.getElementById("loading");
    loading.className = "loading-proc hide";
    setTimeout(() => {
        loading.className = "loading-proc not-display";
    }, 1000);
});

Number.prototype.degree = function() {
    return this * Math.PI / 180;
};
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_window",attrs:{"id":"app"}},[_c('WelcomeText',{attrs:{"vkinit":_vm.vkinit,"page":_vm.page},on:{"nextPage":function (){_vm.page+=1},"prevPage":function (){_vm.page-=1}}}),(_vm.page >= 2)?_c('div',{staticClass:"game_wrapper"},[_c('div',{staticClass:"buttons buttonsLeft"},[_c('div',{class:[
          'button',
          'move',
          _vm.pases_used_change.type === 'move' ? 'checked' : '',
          _vm.pases_used_array.length ? '' : 'inactive' ],on:{"click":function () {
            _vm.buttonClick('move');
          }}}),_c('div',{class:[
          'button',
          'scale',
          _vm.pases_used_change.type === 'scale' ? 'checked' : '',
          _vm.pases_used_array.length ? '' : 'inactive' ],on:{"click":function () {
            _vm.buttonClick('scale');
          }}}),_c('div',{class:[
          'button',
          'rotate',
          _vm.pases_used_change.type === 'rotate' ? 'checked' : '',
          _vm.pases_used_array.length ? '' : 'inactive' ],on:{"click":function () {
            _vm.buttonClick('rotate');
          }}}),_c('div',{class:[
          'button',
          'delete',
          _vm.pases_used_change.type === 'delete' ? 'checked' : '',
          _vm.pases_used_array.length ? '' : 'inactive' ],on:{"click":function () {
            _vm.buttonClick('delete');
          }}}),_c('div',{class:['button', 'clear', _vm.pases_used_array.length ? '' : 'inactive'],on:{"click":_vm.clear_all}})]),_c('div',{staticClass:"work"},_vm._l((_vm.pases_used_array),function(pas,i){return _c('PasUsed',{key:i,attrs:{"pas":pas,"mouse":_vm.mouse_stats,"showBtns":i == _vm.pases_used_change.index},on:{"used":function (event) {
            _vm.used(i, event);
          }}})}),1),_c('div',{staticClass:"buttons buttonsRight"},[_c('div',{class:['button', 'save', _vm.pases_used_array.length ? '' : 'inactive'],on:{"click":_vm.saveToJpeg}}),_c('div',{class:['button', 'info'],on:{"click":function (){_vm.page-=1}}})]),_c('div',{staticClass:"pases"},_vm._l((_vm.pases_array),function(pas,i){return _c('Pas',{key:i,attrs:{"pas":pas},on:{"add_pas":_vm.addPas}})}),1),(_vm.pas_add !== null)?_c('PasAdded',{attrs:{"pas":_vm.pas_add}}):_vm._e(),(_vm.publsh_in_vk)?_c('div',{staticClass:"loading-proc publish_msg"},[_vm._m(0),_c('div',{staticClass:"load-text"},[_vm._v("Публикация на стену...")])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"load-progressbar-wrapper"},[_c('div',{staticClass:"load-progressbar"})])}]

export { render, staticRenderFns }
<template>
  <div class="PasUsed" @mousedown="mouseDown" @touchstart="tDown" :style="{'left': pas.left + 'px', 'top': pas.top + 'px',}">
    <div class="pas" :style="{'width': pas.width + 'px','height': pas.height + 'px', 'transform': ' rotate('+pas.angle+'deg) scale('+pas.scaleX+','+pas.scaleY+')', 'background-image': 'url('+pas.img_url+')'}"></div>
  </div>
</template>

<script>
export default {
  name: "PasUsed",
  props: {
    pas: Object,
    mouse: Object
  },
  data() {
    return {

    };
  },
  methods: {
    tDown(event) {
      this.mouseDown(event.changedTouches[0]);
    },
    mouseDown(event) {
      this.$emit('used', event);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.PasUsed {
  position: absolute;
}

.PasUsed:hover {
  outline:3px dashed #666;
  cursor: pointer;
  z-index: 9;
}

</style>

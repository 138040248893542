<template>
  <div id="app" class="main_window">
    <WelcomeText :vkinit="vkinit" :page="page" @nextPage="()=>{page+=1}" @prevPage="()=>{page-=1}"/>
    <div class="game_wrapper" v-if="page >= 2">
      <div class="buttons buttonsLeft">
        <div
          :class="[
            'button',
            'move',
            pases_used_change.type === 'move' ? 'checked' : '',
            pases_used_array.length ? '' : 'inactive',
          ]"
          @click="
            () => {
              buttonClick('move');
            }
          "
        ></div>
        <div
          :class="[
            'button',
            'scale',
            pases_used_change.type === 'scale' ? 'checked' : '',
            pases_used_array.length ? '' : 'inactive',
          ]"
          @click="
            () => {
              buttonClick('scale');
            }
          "
        ></div>
        <div
          :class="[
            'button',
            'rotate',
            pases_used_change.type === 'rotate' ? 'checked' : '',
            pases_used_array.length ? '' : 'inactive',
          ]"
          @click="
            () => {
              buttonClick('rotate');
            }
          "
        ></div>
        <div
          :class="[
            'button',
            'delete',
            pases_used_change.type === 'delete' ? 'checked' : '',
            pases_used_array.length ? '' : 'inactive',
          ]"
          @click="
            () => {
              buttonClick('delete');
            }
          "
        ></div>
        <div
          :class="['button', 'clear', pases_used_array.length ? '' : 'inactive']"
          @click="clear_all"
        ></div>
      </div>
      <div class="work">
        <PasUsed
          v-for="(pas, i) in pases_used_array"
          :key="i"
          :pas="pas"
          :mouse="mouse_stats"
          @used="
            (event) => {
              used(i, event);
            }
          "
          :showBtns="i == pases_used_change.index"
        />
      </div>
      <div class="buttons buttonsRight">
        <div
          :class="['button', 'save', pases_used_array.length ? '' : 'inactive']"
          @click="saveToJpeg"
        ></div>
        <div
          :class="['button', 'info']"
          @click="()=>{page-=1}"
        ></div>
        <!--<div
          :class="[
            'button',
            'vk_wall',
            pases_used_array.length ? '' : 'inactive',
          ]"
          :style="{ display: vkinit ? 'block' : 'none' }"
          @click="vk_wall_post"
        ></div>-->
      </div>
      <div class="pases">
        <Pas
          v-for="(pas, i) in pases_array"
          :key="i"
          :pas="pas"
          @add_pas="addPas"
        />
      </div>
      <PasAdded v-if="pas_add !== null" :pas="pas_add" />
      <div class="loading-proc publish_msg" v-if="publsh_in_vk">
        <div class="load-progressbar-wrapper">
          <div class="load-progressbar"></div>
        </div>
        <div class="load-text">Публикация на стену...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Pas from "./components/Pas.vue";
import PasAdded from "./components/PasAdded.vue";
import PasUsed from "./components/PasUsed.vue";
import WelcomeText from "./components/WelcomeText.vue";
import axios from "axios";
import { SCALE, MARGINLEFT } from "./main.js";

export default {
  name: "App",
  data() {
    return {
      mouse_stats: {
        X_mouse: 0,
        Y_mouse: 0,
        X_mouse_prev: 0,
        Y_mouse_prev: 0,
        deltaX: 0,
        deltaY: 0,
      },
      pases_array: [],
      pases_used_array: [],
      pases_used_change: { index: -1, type: "move" },
      pas_add: null,
      img_data: {},
      dataURL: undefined,
      vkinit: false,
      publsh_in_vk: false,
      page: 0
    };
  },
  components: {
    Pas,
    PasUsed,
    PasAdded,
    WelcomeText
  },
  mounted() {
    document.body.addEventListener("mousemove", this.mouseMove);
    document.body.addEventListener("touchmove", this.tMove);
    document.body.addEventListener("mouseup", this.mouseUp);
    document.body.addEventListener("touchend", this.tEnd);
    let self = this;
    axios.get("/json/pases.json").then(function (response) {
      self.pases_array = response.data;
      self.pases_array.forEach((el) => {
        self.img_data[el.name] = new Image();
        self.img_data[el.name].src = el.img_url;
      });
      self.img_data["background"] = new Image();
      self.img_data["background"].src = "/images/ui/background1920.jpg";
      self.img_data["logo"] = new Image();
      self.img_data["logo"].src = "/images/ui/logo_2.png";
    });
    if (window.VK) {
      window.VK.init(
        function () {
          self.vkinit = true;
        },
        function () {
          self.vkinit = false;
        },
        "5.120"
      );
    }
  },
  methods: {
    saveToJpeg() {
      if (this.pases_used_array.length > 0) {
        this.saveToDataURL();
        if (this.dataURL != undefined) {
          var link = document.createElement("a");
          document.body.appendChild(link);
          link.href = this.dataURL;
          link.download = "my_pas.jpg";
          link.click();
          document.body.removeChild(link);
          this.dataURL = undefined;
        }
      }
    },
    saveToDataURL() {
      if (this.pases_used_array.length > 0) {
        var canvas = document.createElement("canvas");

        canvas.id = "CursorLayer";
        canvas.width = 1920;
        canvas.height = 1080;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(this.img_data.background, 0, 0);

        ctx.fillStyle = "#000";
        ctx.font = "bold 60pt 'Times New Roman'";
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        ctx.shadowColor = "#ffffff";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;
        ctx.strokeStyle = "#ffffff";
        ctx.fillText("Это мой ПАС – мой знак!", 960, 200);
        ctx.strokeText("Это мой ПАС – мой знак!", 960, 200);

        
        ctx.shadowBlur = 0;

        let pases_a = [];
        this.pases_used_array.forEach((el) => {
          pases_a.push({ ...el });
        });

        let min_x = pases_a[0].left;
        let min_y = pases_a[0].top;
        let max_x = pases_a[0].left + pases_a[0].width;
        let max_y = pases_a[0].top + pases_a[0].height;

        pases_a.forEach((el) => {
          if (min_x > el.left) min_x = el.left;
          if (min_y > el.top) min_y = el.top;
          if (max_x < el.left + el.width) max_x = el.left + el.width;
          if (max_y < el.top + el.height) max_y = el.top + el.height;
        });

        let pas_height = max_y - min_y;
        let pas_width = max_x - min_x;
        pases_a.forEach((el) => {
          el.left = el.left - min_x + 960 - pas_width / 2 + 50;
          el.top = el.top - min_y + 640 - pas_height / 2;
        });

        for (var i = 0; i < pases_a.length; i++) {
          let pas = pases_a[i];
          let rad_angle = (pas.angle * Math.PI) / 180;

          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.translate(pas.left, pas.top);
          ctx.rotate(rad_angle);
          ctx.scale(pas.scaleX, pas.scaleY);
          ctx.drawImage(
            this.img_data[pas.name],
            -pas.width / 2,
            -pas.height / 2
          );
        }

        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.rotate(0);
        ctx.scale(1, 1);
        ctx.drawImage(
          this.img_data.logo,
          canvas.width - 220,
          canvas.height - 170
        );
        this.dataURL = canvas.toDataURL("image/jpeg");
      }
    },
    vk_wall_post() {
      if (this.pases_used_array.length > 0) {
        this.publsh_in_vk = true;
        this.saveToDataURL();
        let self = this;
        setTimeout(() => {
          if (self.dataURL !== undefined) {
            window.VK.api(
              "photos.getWallUploadServer",
              { group_id: "" },
              (data) => {
                const formData = new FormData();
                formData.append("photo", self.dataURL);
                formData.append("url", data.response.upload_url);
                axios({
                  url: "cgi/send_to_vk.py",
                  method: "POST",
                  data: formData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }).then((request) => {
                  window.VK.api(
                    "photos.saveWallPhoto",
                    request.data,
                    (data) => {
                      var photo =
                        "photo" +
                        data.response[0].owner_id +
                        "_" +
                        data.response[0].id;
                      console.log(photo);
                      window.VK.api("wall.post", {
                        message:
                          "Национальный музей Республики Коми\n#Сыктывкар#museumkomi#НМРК#МузейКоми#НочьИскусств2020#КультураРФ\nhttps://museumkomi.ru\nhttps://vk.com/museumkomi",
                        attachments: photo,
                      });
                      self.publsh_in_vk = false;
                    }
                  );
                });
              }
            );
          }
        }, 10);
      }
    },
    used(index, event) {
      this.mouse_stats.X_mouse = (event.clientX - MARGINLEFT) / SCALE;
      this.mouse_stats.Y_mouse = event.clientY / SCALE;
      this.mouse_stats.X_mouse_prev = (event.clientX - MARGINLEFT) / SCALE;
      this.mouse_stats.Y_mouse_prev = event.clientY / SCALE;
      this.mouse_stats.deltaX = 0;
      this.mouse_stats.deltaY = 0;
      if (index !== -1) {
        if (this.pases_used_change.type === "delete") {
          this.pases_used_array.splice(index, 1);
          this.pases_used_change.index = -1;
        } else {
          this.pases_used_change.index = index;
        }
      }
    },
    clear_all() {
      this.pases_used_array.splice(0, this.pases_used_array.length);
    },
    buttonClick(btn) {
      this.pases_used_change.type = btn;
    },
    tMove(event) {
      this.mouseMove(event.changedTouches[0]);
    },
    mouseMove(event) {
      if (event.buttons !== undefined)
        if (event.buttons !== 1) {
          this.pases_used_change.index = -1;
        }

      this.mouse_stats.X_mouse_prev = this.mouse_stats.X_mouse;
      this.mouse_stats.Y_mouse_prev = this.mouse_stats.Y_mouse;
      this.mouse_stats.X_mouse = (event.clientX - MARGINLEFT) / SCALE;
      this.mouse_stats.Y_mouse = event.clientY / SCALE;
      this.mouse_stats.deltaX =
        this.mouse_stats.X_mouse - this.mouse_stats.X_mouse_prev;
      this.mouse_stats.deltaY =
        this.mouse_stats.Y_mouse - this.mouse_stats.Y_mouse_prev;

      if (this.pases_used_change.index !== -1) {
        let pas_use = this.pases_used_array[this.pases_used_change.index];

        if (this.pases_used_change.type === "move") {
          pas_use.left += this.mouse_stats.deltaX;
          pas_use.top += this.mouse_stats.deltaY;
          if (pas_use.top < 0) pas_use.top = 0;
          if (pas_use.left < 0) pas_use.left = 0;
          let work_div = document.getElementsByClassName("work")[0];
          if (pas_use.top + pas_use.height > work_div.offsetHeight)
            pas_use.top = work_div.offsetHeight - pas_use.height;
          if (pas_use.left + pas_use.width > work_div.offsetWidth)
            pas_use.left = work_div.offsetWidth - pas_use.width;
        }
        if (this.pases_used_change.type === "scale") {
          pas_use.scaleX += this.mouse_stats.deltaX / 100;
          pas_use.scaleY += this.mouse_stats.deltaY / 100;

          if (pas_use.scaleX < -3) pas_use.scaleX = -3;
          if (pas_use.scaleX > 3) pas_use.scaleX = 3;
          if (pas_use.scaleY < -3) pas_use.scaleY = -3;
          if (pas_use.scaleY > 3) pas_use.scaleY = 3;
        }
        if (this.pases_used_change.type === "rotate") {
          pas_use.angle += this.mouse_stats.deltaX;
        }
      }

      if (this.pas_add !== null) {
        this.pas_add.top =
          this.mouse_stats.Y_mouse - this.pas_add.height / 2 / SCALE;
        this.pas_add.left =
          this.mouse_stats.X_mouse - this.pas_add.width / 2 / SCALE;
      }
    },
    tEnd() {
      this.mouseUp();
    },
    mouseUp() {
      if (this.pas_add !== null) {
        let work_div = document.getElementsByClassName("work")[0];
        let pas_center = {
          x: this.mouse_stats.X_mouse - this.pas_add.width / 2 + 50,
          y: this.mouse_stats.Y_mouse - this.pas_add.height / 2 + 50,
        };
        if (
          pas_center.y < work_div.offsetHeight &&
          pas_center.y > 0 &&
          pas_center.x < work_div.offsetWidth &&
          pas_center.x > 0
        ) {
          //this.pas_add.left -= work_div.offsetLeft;
          this.pas_add.left -= 100;
          this.pases_used_array.push(this.pas_add);
        }
        this.pas_add = null;
      }
      this.pases_used_change.index = -1;
    },
    addPas(key, event) {
      this.mouse_stats.X_mouse = (event.clientX - MARGINLEFT) / SCALE;
      this.mouse_stats.Y_mouse = event.clientY / SCALE;
      this.mouse_stats.X_mouse_prev = (event.clientX - MARGINLEFT) / SCALE;
      this.mouse_stats.Y_mouse_prev = event.clientY / SCALE;
      this.mouse_stats.deltaX = 0;
      this.mouse_stats.deltaY = 0;
      let pas = { ...this.pases_array[key] };
      pas.top = this.mouse_stats.Y_mouse - pas.height / 2;
      pas.left = this.mouse_stats.X_mouse - pas.width / 2;
      pas.scaleX = 1;
      pas.scaleY = 1;
      pas.angle = 0;
      this.pas_add = pas;
      this.pases_used_change.index = -1;
    },
  },
};
</script>

<style>
html {
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  outline: none;
  background: url("/images/ui/background_op.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body {
  width: 1280px;
  height: 1024px;
  /* width: 100%;
  height: 100%;*/
  margin: 0px;
  padding: 0px;
  transform-origin: top left;
  overflow: hidden;
}


.main_window {
  width: 100%;
  height: 100%;
}

.main_window .game_wrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 200px;
  grid-template-columns: 100px auto 100px;
  grid-template-areas:
    "buttonsLeft work buttonsRight"
    "pases pases pases";
}

.work {
  grid-area: work;
  overflow: hidden;
  position: relative;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonsRight {
  /* border-left: 3px dashed #000; */
  grid-area: buttonsRight;
}

.buttonsLeft {
  /* border-right: 3px dashed #000; */
  grid-area: buttonsLeft;
}

.pases {
  grid-area: pases;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border-top: 3px dashed #000; */
  overflow-y: hidden;
  overflow-x: auto;
}

.button {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
  border-radius: 50%;
  border: 3px solid #000;
}

.button.checked {
  box-shadow: 0px 0px 3px 3px #ecae29;
}

.button:hover {
  box-shadow: 0px 0px 3px 3px #ec6029;
}

.button.inactive {
  opacity: 0.5;
  box-shadow: none;
}

.button.inactive:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.button.close {
  background-image: url("/images/ui/close.svg");
}
.button.delete {
  background-image: url("/images/ui/delete.svg");
}
.button.move {
  background-image: url("/images/ui/move.svg");
}
.button.rotate {
  background-image: url("/images/ui/rotate.svg");
}
.button.scale {
  background-image: url("/images/ui/scale.svg");
}
.button.clear {
  background-image: url("/images/ui/close.svg");
}
.button.save {
  background-image: url("/images/ui/save.svg");
}
.button.vk_wall {
  background-image: url("/images/ui/vk.svg");
}
.button.info {
  background-image: url("/images/ui/info.svg");
}

/* LOADING INDICATOR */

.loading-proc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  transition: opacity 1s;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
}

.loading-proc.hide {
  opacity: 0;
}

.loading-proc.not-display {
  display: none;
}

.loading-proc .load-progressbar-wrapper {
  width: 210px;
  height: 134px;
}

.loading-proc .load-progressbar-wrapper .load-progressbar {
  width: 210px;
  height: 134px;
  background-image: url("/images/ui/logo.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.loading-proc .load-text {
  font-size: 20pt;
  margin-top: 20px;
  animation: shadow 5s infinite linear;
  font-weight: bold;
}

@keyframes shadow {
  0% {
    text-shadow: 0 0 3px rgb(112, 8, 5);
  }
  25% {
    text-shadow: 0 0 3px rgb(180, 83, 31);
  }
  50% {
    text-shadow: 0 0 3px rgb(235, 236, 236);
  }
  75% {
    text-shadow: 0 0 3px rgb(180, 83, 31);
  }
  100% {
    text-shadow: 0 0 3px rgb(112, 8, 5);
  }
}

.publish_msg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  font-size: 2em;
  color: #ffffff;
}
</style>

<template>
  <div class="PasAdded" @mouseup="mouseUp" :style="{'width': pas.width + 'px','height': pas.height + 'px', 'background-image': 'url('+pas.img_url+')', 'top': pas.top+'px', 'left': pas.left+'px'}">

  </div>
</template>

<script>
export default {
  name: 'PasAdded',
  props: {
    pas: Object
  },
  methods: {
    mouseUp() {
      this.$emit('add_pas', this.$vnode.key);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.PasAdded {
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}

.PasAdded:hover {
  cursor:grabbing;
}
</style>

<template>
  <div class="Pas" @mousedown="mouseDown" @touchstart="tDown" :style="{'width': pas.width + 'px', 'background-image': 'url('+pas.img_url+')'}">

  </div>
</template>

<script>
export default {
  name: 'Pas',
  props: {
    pas: Object
  },
  methods: {
    tDown(event) {
      this.mouseDown(event.changedTouches[0]);
    },
    mouseDown(event) {
      this.$emit('add_pas', this.$vnode.key, event);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Pas {
  height:150px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
